<template>
  <div class="market-configuration-resulting-configuration-tab">
    <Select
      :model-value="resultingConfiguration.type"
      :options="options"
      variant="secondary"
      omit-prefix
      @update:model-value="updateType"
    />
    <div
      v-if="resultingConfiguration.type === 'DELAYED'"
      class="market-configuration-resulting-configuration-tab__after-inputs"
    >
      <span>after</span>
      <NumberInput
        :model-value="afterMinutes"
        @update:model-value="(newAfterMinutes) => updateAfterSeconds(newAfterMinutes, afterSeconds)"
      />
      <span>min</span>
      <NumberInput
        :model-value="afterSeconds"
        :max="59"
        @update:model-value="(newAfterSeconds) => updateAfterSeconds(afterMinutes, newAfterSeconds)"
      />
      <span>sec</span>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Select from '@/components/common/Select';
import NumberInput from '@/components/common/NumberInput';

export default {
  components: {
    Select,
    NumberInput,
  },
  props: {
    resultingConfiguration: {
      type: Object,
      default: null,
    },
  },
  emits: {
    'update:resultingConfiguration': {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const afterMinutes = ref(parseInt((props.resultingConfiguration?.afterSeconds || 60) / 60, 10));
    const afterSeconds = ref(parseInt((props.resultingConfiguration?.afterSeconds || 60) % 60, 10));
    const options = [
      {
        value: 'IMMEDIATE',
        label: 'Immediate',
      },
      {
        value: 'DELAYED',
        label: 'Delayed',
      },
    ];

    const updateType = (newValue) => {
      const updatedResultingConfiguration = {
        type: newValue,
      };
      if (newValue === 'DELAYED') {
        updatedResultingConfiguration.afterSeconds = (Number(afterMinutes.value) * 60) + Number(afterSeconds.value);
      }
      emit('update:resultingConfiguration', updatedResultingConfiguration);
    };
    const updateAfterSeconds = (minutes, seconds) => {
      afterMinutes.value = minutes || 0;
      afterSeconds.value = seconds || 0;
      const updatedResultingConfiguration = { ...props.resultingConfiguration };
      updatedResultingConfiguration.afterSeconds = (Number(minutes) * 60) + Number(seconds);
      emit('update:resultingConfiguration', updatedResultingConfiguration);
    };

    return {
      afterMinutes,
      afterSeconds,
      options,
      updateType,
      updateAfterSeconds,
    };
  },
};
</script>

<style lang="scss">
.market-configuration-resulting-configuration-tab {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 20px 0 0;
  box-sizing: border-box;
  width: 100%;
  min-height: 55px;

  .select {
    min-width: 110px;

    .select-dropdown {
      min-width: 100%;
      width: auto;
    }
  }

  &__after-inputs {
    display: flex;
    align-items: center;
    gap: 8px;

    .table-number-input {
      min-width: 44px;
      max-width: 44px;
      width: 44px;
    }

    & > span {
      color: #6D6D6D;
      font-weight: 400;
    }
  }
}
</style>
