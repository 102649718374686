<template>
  <div class="assigned-competitions__root">
    <div class="search-container">
      <TextInput
        v-model="search"
        placeholder="Search competitions and groups"
        icon="search"
        small
      />
    </div>
    <div class="assigned-competitions__scrolbar">
      <div
        v-for="region in regions"
        :key="region.regionName"
        class="region-competition-item"
      >
        <p class="region-item">
          {{ region.regionName }}
        </p>
        <div
          v-for="competition in findCompetitionsByRegion(region)"
          :key="competition.competitionId"
          @mouseover="setCompetitionHovered(competition.competitionId)"
          @mouseleave="setCompetitionHovered(null)"
          class="competition-item"
        >
          <div>
            <p>{{ competition.competitionName }}</p>
          </div>
          <Dropdown
            v-if="isCompetitionHovered(competition)"
            :class="['competition-item-more-dropdown ', isCompetitionHovered(competition) && 'active']"
            icon="more-dots"
            hide-chevron
            right
          >
            <DropdownItem
              class="dropdown-item--danger"
              @click="removeCompetition(competition)"
              clickable
              :disabled="readOnly"
            >
              Remove
            </DropdownItem>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import {
  orderBy, filter, includes, toLower, uniqBy, map,
} from 'lodash';
import TextInput from '@/components/common/TextInput';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';

export default {
  components: {
    TextInput,
    Dropdown,
    DropdownItem,
  },
  props: {
    competitions: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['onRemoveCompetition'],
  setup(props, { emit }) {
    const search = ref('');
    const hoveredCompetition = ref(null);
    const filteredCompetitions = computed(() => filter(
      props.competitions,
      (competition) => (
        includes(toLower(competition.competitionName), toLower(search.value))
        || includes(toLower(competition.regionName), toLower(search.value))
      ),
    ));
    const regions = computed(() => orderBy(uniqBy(map(filteredCompetitions.value, ({ regionId, regionName }) => ({ regionId, regionName })), 'regionId'), 'regionName'));
    const findCompetitionsByRegion = ({ regionName }) => filter(filteredCompetitions.value, { regionName });
    const setCompetitionHovered = (competitionId) => { hoveredCompetition.value = competitionId; };
    const isCompetitionHovered = (competition) => hoveredCompetition.value === competition.competitionId;
    const removeCompetition = (competition) => { emit('onRemoveCompetition', [{ ...competition, applied: false }]); };
    return {
      search,
      hoveredCompetition,
      regions,
      filteredCompetitions,
      findCompetitionsByRegion,
      setCompetitionHovered,
      isCompetitionHovered,
      removeCompetition,
    };
  },
};
</script>
<style lang="scss">
.assigned-competitions__root {
  padding: 13px 0;
  height: 100%;

  .search-container {
    height: 60px;
  }

  .assigned-competitions__scrolbar {
    height: calc(100% - 73px);
    overflow-y: auto;

    .region-competition-item {
      margin-bottom: 30px;
      .region-item, .competition-item {
        font-size: 14px;
        color: $black;
      }
      .region-item {
        margin-bottom: 15px;
      }
      .competition-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 0 12px;
        border-left: 1px solid $gray400;

        p {
          height: 100%;
          padding: 17px 0;
        }

        .button {
          background-color: transparent;
          border: none;
          outline: none;
          box-shadow: none;
          flex-basis: 0;
          flex-grow: 0;
          flex: 0;
        }
        .dropdown-item--danger {
          color: $error500;
        }
      }
    }
  }
}
</style>
