<template>
  <div class="feed-priority-form">
    <div class="feed-priority-form__head">
      <div class="feed-priority-form__title">
        Feed priority
      </div>
      <Button
        variant="primary"
        :disabled="isSubmitDisabled"
        :loading="disabled"
        @click="submitForm"
      >
        Save
      </Button>
    </div>
    <div class="feed-priority-form__body">
      <div class="feed-priority-form__group">
        <div class="feed-priority-form__label">
          Primary feed provider
        </div>
        <Select
          variant="secondary"
          :model-value="primarySource"
          @update:model-value="updatePrimarySource"
          :options="options"
          :disabled="disabled"
          omit-prefix
        />
      </div>
      <div class="feed-priority-form__group">
        <div class="feed-priority-form__label">
          Secondary feed provider
        </div>
        <Select
          variant="secondary"
          :model-value="secondarySource"
          @update:model-value="updateSecondarySource"
          :options="options"
          :disabled="disabled"
          omit-prefix
        />
      </div>
      <div class="feed-priority-form__group">
        <div class="feed-priority-form__label">
          Tertiary feed provider
        </div>
        <Select
          variant="secondary"
          :model-value="tertiarySource"
          @update:model-value="updateTertiarySource"
          :options="options"
          :disabled="disabled"
          omit-prefix
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  filter,
  isEqual,
  take,
  uniq,
} from 'lodash';
import { computed } from 'vue';
import Select from '@/components/common/Select';
import Button from '@/components/common/Button';

export default {
  components: {
    Select,
    Button,
  },
  props: {
    sources: {
      type: Array,
      default: () => [],
    },
    originalSources: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:sources': {
      type: Array,
    },
    submit: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const isSubmitDisabled = computed(() => props.disabled || isEqual(props.sources, props.originalSources));
    const primarySource = computed(() => props.sources?.[0] || '');
    const secondarySource = computed(() => props.sources?.[1] || '');
    const tertiarySource = computed(() => props.sources?.[2] || '');

    const updateSourceAtIndex = (index, newSource) => {
      const updatedSources = [...props.sources]; // declare new array because Array.splice mutates original array
      updatedSources.splice(index, 1, newSource); // also, Array.splice returns deleted elements
      emit('update:sources', updatedSources);
    };
    const updatePrimarySource = (newPrimarySource) => {
      updateSourceAtIndex(0, newPrimarySource);
    };
    const updateSecondarySource = (newSecondarySource) => {
      updateSourceAtIndex(1, newSecondarySource);
    };
    const updateTertiarySource = (newTertiarySource) => {
      updateSourceAtIndex(2, newTertiarySource);
    };
    const submitForm = () => {
      const uniqueFeedSources = uniq(props.sources);
      const validFeedSources = filter(uniqueFeedSources, (source) => source !== '-');
      const paddedFeedSources = [...validFeedSources, '-', '-', '-'];
      const sanitizedFeedSources = take(paddedFeedSources, 3);
      emit('submit', sanitizedFeedSources);
    };

    return {
      isSubmitDisabled,
      primarySource,
      secondarySource,
      tertiarySource,
      updatePrimarySource,
      updateSecondarySource,
      updateTertiarySource,
      submitForm,
    };
  },
};
</script>

<style lang="scss">
.feed-priority-form {
  background: #FFF;
  border: 1px solid #DDD;
  border-radius: 4px;

  &__head {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 15.72px;
    font-weight: 600;
    line-height: 23.58px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px;
    gap: 16px;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__label {
    font-family: 'Rubik', sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 11.85px;
    color: #6D6D6D;
    text-transform: uppercase;
  }
}
</style>
