<template>
  <div class="market-configuration-cashout-configuration-tab">
    <div class="market-configuration-cashout-configuration-tab__headers">
      <div class="market-configuration-cashout-configuration-tab__header">
        Start cashout
      </div>
      <div class="market-configuration-cashout-configuration-tab__header">
        Stop cashout
      </div>
    </div>
    <div class="market-configuration-cashout-configuration-tab__content">
      <div
        v-if="startBoundPeriod"
        class="market-configuration-cashout-configuration-tab__bound"
      >
        <NumberInput
          class="market-configuration-cashout-configuration-tab__input"
          :model-value="startBoundValue.minutes"
          :number-of-decimals="0"
          @update:model-value="(newValue) => updateStartBound(+newValue, startBoundValue.seconds)"
        />
        min
        <NumberInput
          class="market-configuration-cashout-configuration-tab__input"
          :model-value="startBoundValue.seconds"
          :number-of-decimals="0"
          :max="59"
          @update:model-value="(newValue) => updateStartBound(startBoundValue.minutes, +newValue)"
        />
        sec into the {{ startBoundFormattedPeriod }}
      </div>
      <div
        v-else
        class="market-configuration-cashout-configuration-tab__bound market-configuration-cashout-configuration-tab__bound--is-fixed-bound"
      >
        In Pregame
      </div>

      <div
        v-if="stopBoundPeriod"
        class="market-configuration-cashout-configuration-tab__bound"
      >
        <NumberInput
          class="market-configuration-cashout-configuration-tab__input"
          :model-value="stopBoundValue.minutes"
          :number-of-decimals="0"
          @update:model-value="(newValue) => updateStopBound(+newValue, stopBoundValue.seconds)"
        />
        min
        <NumberInput
          class="market-configuration-cashout-configuration-tab__input"
          :model-value="stopBoundValue.seconds"
          :number-of-decimals="0"
          :max="59"
          @update:model-value="(newValue) => updateStopBound(stopBoundValue.minutes, +newValue)"
        />
        sec left in the {{ stopBoundFormattedPeriod }}
      </div>
      <div
        class="market-configuration-cashout-configuration-tab__bound market-configuration-cashout-configuration-tab__bound--is-fixed-bound"
        v-else
      >
        When incident happens
      </div>
    </div>
    <div class="market-configuration-cashout-configuration-tab__headers">
      <div class="market-configuration-cashout-configuration-tab__cashout-header">
        <Toggle
          :model-value="cashoutToggle"
          @update:model-value="value => { toggleCashout(value) }"
        />
        Cashout
      </div>
    </div>
  </div>
</template>

<script>
import {
  findKey, isNil, endsWith, isEmpty,
} from 'lodash';
import { computed, ref } from 'vue';
import { periodLabels } from '@/services/helpers/periods-mapper';
import NumberInput from '@/components/common/NumberInput';
import Toggle from '@/components/common/Toggle';

export default {
  components: {
    NumberInput,
    Toggle,
  },
  props: {
    cashoutConfiguration: {
      type: Object,
      default: null,
    },
  },
  emits: {
    'update:cashoutConfiguration': {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const offeringBounds = computed(() => {
      if (endsWith(props.cashoutConfiguration?.type, '_SIMPLE')) return null;
      if (isEmpty(props.cashoutConfiguration?.offeringBounds)) return null;
      return props.cashoutConfiguration.offeringBounds;
    });
    const startBoundPeriod = computed(() => findKey(offeringBounds.value, (bound) => !isNil(bound.fromSecondInPeriod)));
    const startBoundFormattedPeriod = computed(() => periodLabels[startBoundPeriod.value] || startBoundPeriod.value || '');
    const cashoutToggle = ref(props.cashoutConfiguration?.cashoutEnabled);
    const startBoundValue = computed(() => {
      const fromSecondInPeriod = offeringBounds.value?.[startBoundPeriod.value]?.fromSecondInPeriod;
      if (isNil(fromSecondInPeriod)) return null;
      return {
        minutes: parseInt(fromSecondInPeriod / 60, 10),
        seconds: fromSecondInPeriod % 60,
      };
    });

    const stopBoundPeriod = computed(() => findKey(offeringBounds.value, (bound) => !isNil(bound.secondsLeftInPeriod)));
    const stopBoundFormattedPeriod = computed(() => periodLabels[stopBoundPeriod.value] || stopBoundPeriod.value || '');
    const stopBoundValue = computed(() => {
      const secondsLeftInPeriod = offeringBounds.value?.[stopBoundPeriod.value]?.secondsLeftInPeriod;
      if (isNil(secondsLeftInPeriod)) return null;
      return {
        minutes: parseInt(secondsLeftInPeriod / 60, 10),
        seconds: secondsLeftInPeriod % 60,
      };
    });

    const toggleCashout = (val) => {
      cashoutToggle.value = val;
      emit('update:cashoutConfiguration', {
        ...props.cashoutConfiguration,
        cashoutEnabled: val,
      });
    };

    const updateStartBound = (minutes, seconds) => {
      const newOfferingBoundsValue = {
        ...offeringBounds.value,
        [startBoundPeriod.value]: {
          ...offeringBounds.value[startBoundPeriod.value],
          fromSecondInPeriod: +minutes * 60 + +seconds,
        },
      };
      emit('update:cashoutConfiguration', {
        ...props.cashoutConfiguration,
        offeringBounds: newOfferingBoundsValue,
      });
    };
    const updateStopBound = (minutes, seconds) => {
      const newOfferingBoundsValue = {
        ...offeringBounds.value,
        [stopBoundPeriod.value]: {
          ...offeringBounds.value[stopBoundPeriod.value],
          secondsLeftInPeriod: +minutes * 60 + +seconds,
        },
      };
      emit('update:cashoutConfiguration', {
        ...props.cashoutConfiguration,
        offeringBounds: newOfferingBoundsValue,
      });
    };

    return {
      startBoundPeriod,
      startBoundFormattedPeriod,
      startBoundValue,
      stopBoundPeriod,
      stopBoundFormattedPeriod,
      stopBoundValue,
      updateStartBound,
      updateStopBound,
      cashoutToggle,
      toggleCashout,
      Toggle,
    };
  },
};
</script>

<style lang="scss">
.market-configuration-cashout-configuration-tab {
  padding-top: 20px;
  padding-left: 8px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 70px;

  &__headers {
    width: 100%;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    color: #191414;
    margin-bottom: 12px;
  }

  &__header {
  }

  &__cashout-header {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 33px;
    margin-bottom: 16px;
  }

  &__bound {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
    align-items: center;
    color:#6D6D6D;
    font-weight: 400;

    &--is-fixed-bound {
      background: #FAFAFA;
      border: 1px solid #F0F0F0;
      box-sizing: border-box;
      border-radius: 4px;
      color: #A9A9A9;
      display: flex;
      align-items: center;
      padding: 0 8px;
    }
  }

  &__input {
    height: 100%;
    width: 44px;
    color: #191414;

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>]
