<template>
  <div class="risk-configurations">
    <div class="risk-configurations__header">
      <div class="risk-configurations__header-title">
        Market suspension logic
        <Tooltip
          text="Suspension logic applies to selected competition"
          top
        >
          <Icon
            name="info"
          />
        </Tooltip>
      </div>
      <Button
        variant="primary"
        :disabled="isSavingDisabled"
        @click="submit"
      >
        Save
      </Button>
    </div>
    <div class="risk-configurations__content">
      <div class="risk-configurations__nav-list">
        <div
          v-for="config in allFormData"
          :key="config.id"
          :class="[
            'risk-configurations__nav-item',
            { 'risk-configurations__nav-item--is-active': selectedConfigId === config.id },
          ]"
          @click="selectConfig(config.id)"
        >
          {{ config.name }}
        </div>
      </div>
      <div
        v-if="selectedFormData"
        class="risk-configurations__tab-panel"
      >
        <div
          v-if="selectedFormData.parameters?.length"
          class="risk-configurations__text"
        >
          Suspend all markets in case of:
        </div>
        <div
          v-else
          class="risk-configurations__text"
        >
          No game risk rules at the moment.
        </div>
        <div
          v-if="selectedFormData.parameters?.length"
          class="risk-configurations__grid"
        >
          <div
            v-for="param in selectedFormData.parameters"
            :key="param.id"
            :title="param.label"
            class="risk-configurations__parameter"
            @click.stop.prevent="setParameter(param.id, !param.value)"
          >
            <Checkbox
              class="risk-configurations__parameter-input"
              :model-value="param.value"
              @click.stop.prevent="setParameter(param.id, !param.value)"
            />
            <span class="risk-configurations__parameter-label">
              {{ param.label }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { find, map, isEqual } from 'lodash';
import { ref, computed } from 'vue';
import { parseAllRiskConfigurationFormData } from '@/services/helpers/risk-configuration-form';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    Icon,
    Tooltip,
    Button,
    Checkbox,
  },
  props: {
    originalRiskConfigurations: {
      type: Array,
      required: true,
    },
    riskConfigurations: {
      type: Array,
      required: true,
    },
  },
  emits: {
    'update:riskConfigurations': {
      type: Array,
    },
    submit: {
    },
  },
  setup(props, { emit }) {
    const selectedConfigId = ref(props.riskConfigurations?.[0]?.gameRiskTemplateId || '');

    const allFormData = computed(() => parseAllRiskConfigurationFormData(props.riskConfigurations));
    const selectedFormData = computed(() => find(allFormData.value, { id: selectedConfigId.value }) || null);
    const isSavingDisabled = computed(() => isEqual(props.originalRiskConfigurations, props.riskConfigurations));

    const selectConfig = (id) => {
      selectedConfigId.value = id;
    };
    const setParameter = (id, newValue) => {
      const newRiskConfigurations = map(props.riskConfigurations, (config) => {
        if (selectedConfigId.value !== config.gameRiskTemplateId) return config;
        return {
          ...config,
          gameRiskConfiguration: {
            ...config.gameRiskConfiguration,
            [id]: !!newValue,
          },
        };
      });
      emit('update:riskConfigurations', newRiskConfigurations);
    };
    const submit = () => {
      emit('submit');
    };

    return {
      allFormData,
      selectedConfigId,
      selectedFormData,
      isSavingDisabled,
      selectConfig,
      setParameter,
      submit,
    };
  },
};
</script>

<style lang="scss">
.risk-configurations {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #DDD;
  border-radius: 4px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px;

    &-title {
      display: flex;
      align-items: center;
      gap: 4px;
      font-family: 'Poppins', sans-serif;
      font-size: 15.72px;
      font-weight: 600;
      line-height: 23.58px;
      color: #191414;

      .tooltip {
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.22px;
      }

      .icon {
        svg {
          g {
            path {
              stroke: #CDCDCD;
            }
          }
        }
      }
    }
  }

  &__content {
    padding: 0 16px 16px;
  }

  &__nav {
    &-list {
      display: flex;
      margin-bottom: 16px;
      border-bottom: 2px solid #F0F0F0;
    }

    &-item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      font-size: 14px;
      line-height: 16px;
      border-bottom: 2px solid #F0F0F0;
      margin-bottom: -2px;
      min-width: 45px;
      cursor: pointer;

      &--is-active {
        border-bottom: 2px solid #003C3C;
      }
    }
  }

  &__tab-panel {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__text {
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 16px;
    color: #191414;
  }

  &__grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__parameter {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    overflow: hidden;
    padding: 0 2px;

    &-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
