<template>
  <Modal
    title="List of competitions"
    :visible="isActive"
    class="modal-container"
    @close="close"
  >
    <div class="assigned-competitions-modal__content">
      <p class="modal-subtitle">
        Select the competitions you want to include in this template.
      </p>
      <div class="search-container">
        <TextInput
          v-model="search"
          placeholder="Search competitions and groups"
          icon="search"
          small
        />
      </div>
      <div class="competitions-list">
        <div
          v-for="region in regions"
          :key="region.regionName"
          class="region-competition-item"
        >
          <div class="region-item">
            <Checkbox
              :disabled="readOnly"
              :model-value="isRegionApplied(region)"
              @update:modelValue="regionToggled(region)"
            />
            <p>{{ region.regionName }}</p>
          </div>
          <div
            v-for="competition in findCompetitionsByRegion(region)"
            :key="competition.competitionId"
          >
            <div class="competition-item">
              <Checkbox
                :disabled="readOnly"
                :model-value="competition.applied"
                @update:modelValue="competitionToggled(competition)"
              />
              <p>{{ competition.competitionName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="actions__root">
        <Button
          variant="secondary"
          @click="close"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          :disabled="readOnly"
          @click="addNewCompetitions"
        >
          Save
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import {
  ref, computed, onMounted, onBeforeUnmount,
} from 'vue';
import {
  uniqBy, filter, includes, toLower, cloneDeep, find, orderBy, map, forEach,
} from 'lodash';
import Modal from '@/components/common/Modal';
import Checkbox from '@/components/common/Checkbox';
import TextInput from '@/components/common/TextInput';
import Button from '@/components/common/Button';

export default {
  components: {
    Modal,
    Checkbox,
    TextInput,
    Button,
  },
  emits: ['close', 'onSave'],
  props: {
    isActive: {
      type: Boolean,
      default: () => false,
    },
    competitions: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const search = ref('');
    const competitionList = ref(cloneDeep(props.competitions));
    const changedCompetitionList = ref([]);
    const filteredCompetitions = computed(() => filter(
      competitionList.value,
      (competition) => (
        includes(toLower(competition.competitionName), toLower(search.value))
        || includes(toLower(competition.regionName), toLower(search.value))
      ),
    ));
    const findCompetitionsByRegion = ({ regionName }) => filter(filteredCompetitions.value, { regionName });
    const regions = computed(() => orderBy(uniqBy(map(filteredCompetitions.value, ({ regionId, regionName }) => ({ regionId, regionName })), 'regionId'), 'regionName'));
    const isRegionApplied = (region) => {
      const competitionsByRegion = findCompetitionsByRegion(region);
      const appliedCompetitions = filter(competitionsByRegion, (c) => c.applied);
      return competitionsByRegion.length === appliedCompetitions.length;
    };
    const close = () => {
      emit('close');
    };

    const competitionToggled = (competition) => {
      const originalChangedCompetition = find(competitionList.value, { competitionId: competition.competitionId });
      originalChangedCompetition.applied = !originalChangedCompetition.applied;

      const changedCompetition = find(changedCompetitionList.value, { competitionId: competition.competitionId });
      if (changedCompetition) {
        changedCompetitionList.value = filter(changedCompetitionList.value, (c) => c.competitionid !== changedCompetition.competitionId);
      } else {
        changedCompetitionList.value.push(originalChangedCompetition);
      }
    };

    const regionToggled = (region) => {
      const regionApplied = isRegionApplied(region);
      const originalCompetitions = findCompetitionsByRegion(region);
      forEach(originalCompetitions, (c) => {
        if (regionApplied || !c.applied) {
          competitionToggled(c);
        }
      });
    };

    const addNewCompetitions = () => {
      emit('onSave', changedCompetitionList.value);
      close();
    };

    const onEscEventListener = (e) => {
      if (e.key === 'Escape') { close(); }
    };
    onMounted(() => document.addEventListener('keydown', onEscEventListener));
    onBeforeUnmount(() => document.removeEventListener('keydown', onEscEventListener));

    return {
      search,
      regions,
      isRegionApplied,
      findCompetitionsByRegion,
      competitionToggled,
      addNewCompetitions,
      regionToggled,
      close,
    };
  },
};
</script>
<style lang="scss">
.modal-container .modal__dialog {
    height: 80%;
    min-width: 530px;

    .modal__content {
        overflow: hidden;
    }
}
.assigned-competitions-modal__content {
    height: 100%;
    padding: 0 32px 32px 32px;

    .modal-subtitle {
        font-size: 12px;
        color: $gray700;
    }

    .search-container {
        height: 70px;
        padding-top: 13px;
    }

    .competitions-list {
        padding-left: 2px;
        height: calc(100% - 140px);
        overflow-y: auto;
        .region-competition-item {
          margin-bottom: 30px;
          .region-item, .competition-item {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $black;
            p {
                margin-left: 8px;
            }
          }
          .region-item {
            margin-bottom: 15px;
          }
          .competition-item {
            padding: 17px 0 17px 12px;
            border-left: 1px solid $gray400;
          }
        }
    }

    .actions__root {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        padding-top: 18px;

        .button {
            display: flex;
            justify-content: center;

            &.button--primary {
                width: 90px;
            }
        }
    }
}
</style>
