<template>
  <div class="market-configuration-line-configuration-tab">
    <div class="market-configuration-line-configuration-tab__form-group">
      <div class="market-configuration-line-configuration-tab__form-label">
        Minimum line
      </div>
      <NumberInput
        class="market-configuration-line-configuration-tab__form-control"
        :model-value="minLineInput"
        :placeholder="minLinePlaceholder"
        :number-of-decimals="1"
        :max="300"
        :is-error="isMinLineInvalid"
        :error-message="minLineErrorMessage"
        accept-negative
        @update:model-value="updateMinLine"
      />
    </div>
    <div class="market-configuration-line-configuration-tab__form-group">
      <div class="market-configuration-line-configuration-tab__form-label">
        Maximum line
      </div>
      <NumberInput
        class="market-configuration-line-configuration-tab__form-control"
        :model-value="maxLineInput"
        :placeholder="maxLinePlaceholder"
        :number-of-decimals="1"
        :max="300"
        :is-error="isMaxLineInvalid"
        :error-message="maxLineErrorMessage"
        accept-negative
        @update:model-value="updateMaxLine"
      />
    </div>
    <div class="market-configuration-line-configuration-tab__form-group">
      <div class="market-configuration-line-configuration-tab__form-label">
        Line increment
      </div>
      <NumberInput
        class="market-configuration-line-configuration-tab__form-control"
        :model-value="lineIncrementInput"
        :placeholder="lineIncrementPlaceholder"
        :number-of-decimals="1"
        :is-error="isLineIncrementInvalid"
        :error-message="lineIncrementErrorMessage"
        @update:model-value="updateLineIncrement"
      />
    </div>
    <div class="market-configuration-line-configuration-tab__form-group">
      <div class="market-configuration-line-configuration-tab__form-label">
        Line type
      </div>
      <div class="market-configuration-line-configuration-tab__form-control">
        {{ lineType }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import {
  composeLineConfiguration,
  composeWithLineTemplateConfiguration,
  composeLineType,
  isLineConfigurationValueInvalid,
} from '@/services/helpers/line-configuration';
import NumberInput from '@/components/common/NumberInput';

export default {
  components: {
    NumberInput,
  },
  props: {
    defaultTemplateConfiguration: {
      type: Object,
      required: true,
    },
    templateConfiguration: {
      type: Object,
      default: null,
    },
  },
  emits: {
    'update:templateConfiguration': {
      type: Object,
    },
  },
  setup(props, { emit }) {
    // Configuration
    const lineConfiguration = computed(() => composeLineConfiguration(props.templateConfiguration, props.defaultTemplateConfiguration));
    const minLine = computed(() => lineConfiguration.value.minLine);
    const maxLine = computed(() => lineConfiguration.value.maxLine);
    const lineIncrement = computed(() => lineConfiguration.value.lineIncrement);
    const lineType = computed(() => composeLineType(minLine.value, lineIncrement.value));

    // Inputs
    const minLineInput = computed(() => props.templateConfiguration?.minLine);
    const minLinePlaceholder = computed(() => props.defaultTemplateConfiguration.minLine);
    const maxLineInput = computed(() => props.templateConfiguration?.maxLine);
    const maxLinePlaceholder = computed(() => props.defaultTemplateConfiguration.maxLine);
    const lineIncrementInput = computed(() => props.templateConfiguration?.lineIncrement);
    const lineIncrementPlaceholder = computed(() => props.defaultTemplateConfiguration.lineIncrement);

    // Validations
    const isMinMaxLineOutOfBounds = computed(() => minLine.value > maxLine.value);
    const isMinLineInvalid = computed(() => isMinMaxLineOutOfBounds.value || isLineConfigurationValueInvalid(minLine.value));
    const isMaxLineInvalid = computed(() => isMinMaxLineOutOfBounds.value || isLineConfigurationValueInvalid(maxLine.value));
    const isLineIncrementInvalid = computed(() => isLineConfigurationValueInvalid(lineIncrement.value));

    // Error messages
    const minLineErrorMessage = computed(() => {
      if (isMinMaxLineOutOfBounds.value) return 'Min line must be less than max line';
      if (isMinLineInvalid.value) return 'Min line must be integer or .5 decimal';
      return '';
    });
    const maxLineErrorMessage = computed(() => {
      if (isMinMaxLineOutOfBounds.value) return 'Max line must be greater than min line';
      if (isMaxLineInvalid.value) return 'Max line must be integer or .5 decimal';
      return '';
    });
    const lineIncrementErrorMessage = computed(() => {
      if (isLineIncrementInvalid.value) return 'Line increment must be integer or .5 decimal';
      return '';
    });

    // Actions
    const updateMinLine = (newMinLine) => {
      emit('update:templateConfiguration', composeWithLineTemplateConfiguration({
        minLine: newMinLine,
        maxLine: maxLineInput.value,
        lineIncrement: lineIncrementInput.value,
      }));
    };
    const updateMaxLine = (newMaxLine) => {
      emit('update:templateConfiguration', composeWithLineTemplateConfiguration({
        minLine: minLineInput.value,
        maxLine: newMaxLine,
        lineIncrement: lineIncrementInput.value,
      }));
    };
    const updateLineIncrement = (newLineIncrement) => {
      emit('update:templateConfiguration', composeWithLineTemplateConfiguration({
        minLine: minLineInput.value,
        maxLine: maxLineInput.value,
        lineIncrement: newLineIncrement,
      }));
    };

    return {
      minLine,
      maxLine,
      lineIncrement,
      lineType,
      minLineInput,
      minLinePlaceholder,
      maxLineInput,
      maxLinePlaceholder,
      lineIncrementInput,
      lineIncrementPlaceholder,
      isMinLineInvalid,
      isMaxLineInvalid,
      isLineIncrementInvalid,
      minLineErrorMessage,
      maxLineErrorMessage,
      lineIncrementErrorMessage,
      updateMinLine,
      updateMaxLine,
      updateLineIncrement,
    };
  },
};
</script>

<style lang="scss">
.market-configuration-line-configuration-tab {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 220px;

  &__form {
    &-group {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &-label {
      color: #A9A9A9;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
    }

    &-control {
      height: 33px;
      min-height: 33px;
      max-height: 33px;
    }
  }
}
</style>
